// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-2-d-nft-game-development-services-js": () => import("./../../../src/pages/2d-nft-game-development-services.js" /* webpackChunkName: "component---src-pages-2-d-nft-game-development-services-js" */),
  "component---src-pages-3-d-nft-game-development-services-2-js": () => import("./../../../src/pages/3d-nft-game-development-services-2.js" /* webpackChunkName: "component---src-pages-3-d-nft-game-development-services-2-js" */),
  "component---src-pages-3-d-nft-game-development-services-js": () => import("./../../../src/pages/3d-nft-game-development-services.js" /* webpackChunkName: "component---src-pages-3-d-nft-game-development-services-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blockchain-game-development-services-js": () => import("./../../../src/pages/blockchain-game-development-services.js" /* webpackChunkName: "component---src-pages-blockchain-game-development-services-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cryptocurrency-exchange-development-services-js": () => import("./../../../src/pages/cryptocurrency-exchange-development-services.js" /* webpackChunkName: "component---src-pages-cryptocurrency-exchange-development-services-js" */),
  "component---src-pages-cryptocurrency-wallet-development-services-js": () => import("./../../../src/pages/cryptocurrency-wallet-development-services.js" /* webpackChunkName: "component---src-pages-cryptocurrency-wallet-development-services-js" */),
  "component---src-pages-dao-development-services-js": () => import("./../../../src/pages/dao-development-services.js" /* webpackChunkName: "component---src-pages-dao-development-services-js" */),
  "component---src-pages-defi-staking-platform-development-services-js": () => import("./../../../src/pages/defi-staking-platform-development-services.js" /* webpackChunkName: "component---src-pages-defi-staking-platform-development-services-js" */),
  "component---src-pages-ico-development-services-js": () => import("./../../../src/pages/ico-development-services.js" /* webpackChunkName: "component---src-pages-ico-development-services-js" */),
  "component---src-pages-ido-development-services-js": () => import("./../../../src/pages/ido-development-services.js" /* webpackChunkName: "component---src-pages-ido-development-services-js" */),
  "component---src-pages-igo-launchpad-development-services-js": () => import("./../../../src/pages/igo-launchpad-development-services.js" /* webpackChunkName: "component---src-pages-igo-launchpad-development-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-at-softtik-js": () => import("./../../../src/pages/life-at-softtik.js" /* webpackChunkName: "component---src-pages-life-at-softtik-js" */),
  "component---src-pages-metaverse-3-d-animation-js": () => import("./../../../src/pages/metaverse-3d-animation.js" /* webpackChunkName: "component---src-pages-metaverse-3-d-animation-js" */),
  "component---src-pages-metaverse-development-services-js": () => import("./../../../src/pages/metaverse-development-services.js" /* webpackChunkName: "component---src-pages-metaverse-development-services-js" */),
  "component---src-pages-metaverse-enterprise-solutions-js": () => import("./../../../src/pages/metaverse-enterprise-solutions.js" /* webpackChunkName: "component---src-pages-metaverse-enterprise-solutions-js" */),
  "component---src-pages-metaverse-game-development-services-js": () => import("./../../../src/pages/metaverse-game-development-services.js" /* webpackChunkName: "component---src-pages-metaverse-game-development-services-js" */),
  "component---src-pages-metaverse-gaming-space-development-services-js": () => import("./../../../src/pages/metaverse-gaming-space-development-services.js" /* webpackChunkName: "component---src-pages-metaverse-gaming-space-development-services-js" */),
  "component---src-pages-metaverse-healthcare-solutions-js": () => import("./../../../src/pages/metaverse-healthcare-solutions.js" /* webpackChunkName: "component---src-pages-metaverse-healthcare-solutions-js" */),
  "component---src-pages-metaverse-launchpad-development-services-js": () => import("./../../../src/pages/metaverse-launchpad-development-services.js" /* webpackChunkName: "component---src-pages-metaverse-launchpad-development-services-js" */),
  "component---src-pages-metaverse-rental-space-services-js": () => import("./../../../src/pages/metaverse-rental-space-services.js" /* webpackChunkName: "component---src-pages-metaverse-rental-space-services-js" */),
  "component---src-pages-metaverse-token-development-services-js": () => import("./../../../src/pages/metaverse-token-development-services.js" /* webpackChunkName: "component---src-pages-metaverse-token-development-services-js" */),
  "component---src-pages-metaverse-wallet-development-services-js": () => import("./../../../src/pages/metaverse-wallet-development-services.js" /* webpackChunkName: "component---src-pages-metaverse-wallet-development-services-js" */),
  "component---src-pages-nft-development-service-js": () => import("./../../../src/pages/nft-development-service.js" /* webpackChunkName: "component---src-pages-nft-development-service-js" */),
  "component---src-pages-nft-game-development-services-2-js": () => import("./../../../src/pages/nft-game-development-services-2.js" /* webpackChunkName: "component---src-pages-nft-game-development-services-2-js" */),
  "component---src-pages-nft-game-development-services-js": () => import("./../../../src/pages/nft-game-development-services.js" /* webpackChunkName: "component---src-pages-nft-game-development-services-js" */),
  "component---src-pages-nft-launchpad-development-services-js": () => import("./../../../src/pages/nft-launchpad-development-services.js" /* webpackChunkName: "component---src-pages-nft-launchpad-development-services-js" */),
  "component---src-pages-nft-marketplace-development-services-js": () => import("./../../../src/pages/nft-marketplace-development-services.js" /* webpackChunkName: "component---src-pages-nft-marketplace-development-services-js" */),
  "component---src-pages-nft-minting-website-development-services-js": () => import("./../../../src/pages/nft-minting-website-development-services.js" /* webpackChunkName: "component---src-pages-nft-minting-website-development-services-js" */),
  "component---src-pages-pos-blockchain-development-services-js": () => import("./../../../src/pages/pos-blockchain-development-services.js" /* webpackChunkName: "component---src-pages-pos-blockchain-development-services-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-real-estate-tokenization-services-js": () => import("./../../../src/pages/real-estate-tokenization-services.js" /* webpackChunkName: "component---src-pages-real-estate-tokenization-services-js" */),
  "component---src-pages-smart-contract-development-services-js": () => import("./../../../src/pages/smart-contract-development-services.js" /* webpackChunkName: "component---src-pages-smart-contract-development-services-js" */),
  "component---src-pages-sto-development-services-js": () => import("./../../../src/pages/sto-development-services.js" /* webpackChunkName: "component---src-pages-sto-development-services-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-token-development-services-js": () => import("./../../../src/pages/token-development-services.js" /* webpackChunkName: "component---src-pages-token-development-services-js" */),
  "component---src-pages-tokenomics-development-services-js": () => import("./../../../src/pages/tokenomics-development-services.js" /* webpackChunkName: "component---src-pages-tokenomics-development-services-js" */),
  "component---src-pages-unity-game-development-services-js": () => import("./../../../src/pages/unity-game-development-services.js" /* webpackChunkName: "component---src-pages-unity-game-development-services-js" */),
  "component---src-pages-vr-game-development-services-js": () => import("./../../../src/pages/vr-game-development-services.js" /* webpackChunkName: "component---src-pages-vr-game-development-services-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

